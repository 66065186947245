export class LsMigrationError extends Error {
  constructor(message) {
    super(message);
  }
}

export class LsSchemaVersionError extends Error {
  constructor(message) {
    super(message);
  }
}
