/**
 * This is the client side configuration template. To add your own configuration, please copy this file and rename it to 'config.js'
 **/
const config = {
  recapcha: {
    sitekey: '6LcpU3sUAAAAALyfgUakV_BNc5Ck1RRxTy8oFWrC'
  },
  network: {
    timeout: 15000,
  },
  analytics: {
    trackingId: "UA-129314992-1",
  },
};
export default config;
